<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_c.png" alt="" width="28">
        <h2>检疫确认</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="activeInfo">
      <span>实验编号：{{ subDetail.number }}</span>
      <span>项目负责人：{{ subDetail.dirNickname }}</span>
      <span>计划开始时间：{{ subDetail.startTime | formatDay }}</span>
      <span>动物品系：{{ subDetail.strain }}</span>
      <p>实验名称：{{ subDetail.name }}</p>
    </div>
    <div class="commonClass">
      <table class="table">
        <tr>
          <th colspan="1">动物品系</th>
          <td colspan="2">{{ activeInfo.strain }}</td>
          <th>实验编号</th>
          <td>{{ subDetail.number }}</td>
          <th>实验负责人</th>
          <td>{{ subDetail.dirNickname }}</td>
          <th width="100">入驻日期</th>
          <td colspan="3">{{ activeInfo.inTime  | formatDay }}</td>
        </tr>
        <tr>
          <th colspan="2">供应商</th>
          <td colspan="10">{{ activeInfo.aniSupply.supplier }}</td>
        </tr>
        <tr>
          <td colspan="10">合格动物</td>
        </tr>
        <tr>
          <td colspan="3">笼架编号</td>
          <td colspan="2">笼舍编号</td>
          <td colspan="1">性别</td>
          <!--          <td colspan="2">数量</td>-->
          <td colspan="3">观察状态</td>
        </tr>
        <tr v-for="item in qualified" :key="item.id">
          <td colspan="3">{{ item.frameNo }}</td>
          <td colspan="2">{{ item.cageNo }}</td>
          <td colspan="1"> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <!--          <td colspan="2">{{ item.count }}</td>-->
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.quaHandle }}</td>
        </tr>
        <tr>
          <td colspan="10">不合格动物</td>
        </tr>
        <tr>
          <td colspan="3">笼架编号</td>
          <td colspan="2">笼舍编号</td>
          <td colspan="1">性别</td>
          <!--          <td colspan="2">数量</td>-->
          <td colspan="3">观察状态</td>
        </tr>
        <tr v-for="item in unqualified" :key="item.id">
          <td colspan="3">{{ item.frameNo }}</td>
          <td colspan="2">{{ item.cageNo }}</td>
          <td colspan="1"> {{ item.gender == 0 ? '♀' : '♂' }}</td>
          <!--          <td colspan="2">{{ item.count }}</td>-->
          <td colspan="3">{{ item.symptom }}</td>
        </tr>
        <tr>
          <td colspan="4">处理方法</td>
          <td colspan="6">{{ activeInfo.aniQuar.unquaHandle }}</td>
        </tr>
        <tr>
          <td colspan="3">检疫人</td>
          <td colspan="2">{{ activeInfo.aniQuar.applyerName }}</td>
          <td colspan="2">检疫时间</td>
          <td colspan="2">{{ activeInfo.aniQuar.applyTime  | formatDay }}</td>
        </tr>

      </table>
      <h3 class="title">兽医确认</h3>
      <template v-if="state==1">
        <el-form ref="addForm" size="small" :model="addExper" class="addForm" label-suffix=":" :rules="rules"
                 label-width="90px">
          <el-form-item label="确认结果">
            <el-radio-group v-model="addExper.status" class="radioGroup">
              <el-radio :label="2">通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="兽医意见" :prop="addExper.status==3?'remark':''">
            <el-input v-model="addExper.remark" type="textarea" rows="4" placeholder="请输入意见"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="submit('addForm')">确认提交</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="state==0"></template>
      <template v-else>
        <div class="aniSupply">
          <span><label>确认结果：</label>{{ activeInfo.aniQuar.state == 2 ? '通过' : '拒绝' }}</span>
          <span><label>兽医意见：</label>{{ activeInfo.aniQuar.reviewRemark }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
export default {
  name: "quarantineDetail",
  data() {
    return {
      subId: this.$route.query.subId,
      state: this.$route.query.state,
      subDetail: {},
      activeInfo: {
        aniSupply: {},
        aniQuar: {},
      },
      qualified: [],
      unqualified: [],
      addExper: {
        status: 2,
        remark: ""
      },
      rules: {
        remark: [
          {required: true, message: '请输入意见', trigger: 'blur'},
        ],
      }
    }
  },
  mounted() {
    // 查询基本信息
    this.$get("/subject/" + this.subId).then((res) => {
      if (res.status == 200) {
        this.subDetail = res.data
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
    this.getAnimaInfo()
  },
  methods: {
    getAnimaInfo() {
      // 查询实验信息
      this.$get('/subject/quar/' + this.subId).then((res) => {
        if (res.status == 200) {
          this.activeInfo = res.data.aniOrder
          res.data.aniOrder.quarResultList.forEach(obj => {
            // 筛选合格不合格
            if (obj.isQualify == 0) {
              this.unqualified.push(this.deepClone4(obj))
            } else {
              this.qualified.push(this.deepClone4(obj))
            }
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    submit(task) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          this.addExper.id = this.activeInfo.aniQuar.id
          this.addExper.sid = this.subId
          this.$put("/ani/quar/status/" + this.addExper.id, this.addExper).then(res => {
            this.$message.success('提交成功')
            this.$router.go(-1)
          }).catch(() => {
            this.$message.error('提交失败')
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/proDetail";
</style>
